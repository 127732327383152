import { default as $ } from 'jquery';

$(document).ready(function () {
    $("#searchButton").on("click", function(){
        $("#searchform").toggleClass("-show");
    });

    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 100) {
            $('#header').addClass('-dark');
        } else {
            $('#header').removeClass('-dark');
        }
    });
});