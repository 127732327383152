import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load",function() {
    $(window).scroll(function() {
      var pageTop = $(document).scrollTop();
      var windowBottom = pageTop + $(window).height();

      $(".slideDown").each(function() {
        var objectBottom = $(this).offset().top;
    
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('-slide');
        }
      });

      $(".addAnimation").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fadeIn');
        }
      });

      $(".addImageAnimation").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('img-animation');
        }
      });

      $(".addAnimation-button").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('m-fadeIn-button');
        }
      });

      $(".cardAnimation").each(function() {
        var objectPosition = $(this).position().top + $(this).offset().top + $(this).outerHeight(true);
        var objectHeight = $(this).height();
        var objectBottom = objectPosition - objectHeight;
        
        if (objectBottom < windowBottom) {
          jQuery(this).find('.-card').addClass('cardFade');
        }
      });
  }).scroll(); //invoke scroll-handler on page-load
});
