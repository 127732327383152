import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(window).scroll(function() {
        var pageTop = $(document).scrollTop();
        var windowBottom = pageTop + $(window).height();
        
        $('.m-media-video').each(function(i, obj) {
            var objectBottom = $(this).offset().top;
            
            if (objectBottom < windowBottom) {
                $(this).find("iframe").prop("src", function(){
                    return $(this).data("src");
                });
            }
        });

        $('.m-video-container').each(function(i, obj) {
            var objectBottom = $(this).offset().top;
            
            if (objectBottom < windowBottom) {
                $(this).find("iframe").prop("src", function(){
                    return $(this).data("src");
                });
            }
        });
    });
});